@import '../../styles/variables';
@import '../../styles/mixin';

.container {
  display: flex;
  align-items: center;
  
  .headding {
    font-size: calc(1.1rem + 1.5vw);
    line-height: calc(1.9rem + 1.5vw);
    font-weight: 700;
    color: $gray-500;

    @include media(2xl) {
      font-size: 40px;
    }
  }

  .headding__small {
    font-size: $text-2xl !important;
    line-height: 32px !important;
    font-weight: 700;
    color: $gray-500;
  }

  .heading__item {
    font-size: $text-xl !important;
    line-height: 26px !important;
  }

  .heading__large {
    font-size: calc(1.4rem + 1.5vw) !important;
    font-weight: 700;
    color: $gray-500;

    @include media(2xl) {
      font-size: $text-5xl !important;
      line-height: 62px;
    }
  }

  .layout__flex_column {
    display: flex;
    flex-direction: column;
  }

  .layout__flex_row {
    display: flex;
    flex-direction: row;
  }

  .content {
    @extend .layout__flex_column;
    align-items: center;
    text-align: center;
    padding: 0 20px;
    gap: 48px;

    @include media(sm) {
      padding: 0 40px;
    }

    @include media(xl) {
      padding: 0 90px;
    }

    @include media(2xl) {
      padding: 0 107px;
    }

    @include media(3xl) {
      padding: 0 144px;
    }
  }

  .body__contents {
    display: grid;
    width: 100%;
    grid-template-columns: repeat(1, minmax(0, 1fr));
    gap: 24px;

    @include media(sm) {
      grid-template-columns: repeat(2, minmax(0, 1fr));
    }

    @include media(lg) {
      grid-template-columns: repeat(3, minmax(0, 1fr));
    }

    &_two_columns {
      @include media(lg) {
        grid-template-columns: repeat(2, minmax(0, 1fr));
      }

      p {
        @extend .heading__item;
      }
    }

    &_four_columns {
      @include media(lg) {
        grid-template-columns: repeat(4, minmax(0, 1fr));
      }
    }
  }

  .content__text {
    @extend .layout__flex_column;
    max-width: 814px;
    width: 100%;
    align-items: center;
    text-align: center;
    margin: auto 0;
    gap: 12px;

    p {
      @extend .heading__item;
      margin-top: 4px;
    }
  }

  .body__item {
    @extend .layout__flex_column;
    border-radius: $border-radius-md;
    height: 100%;
    padding: 24px;
    gap: 8px;

    &_link {
      margin: 0;
      padding: 0;
    }

    &_border {
      border: 1px solid $gray-200;
    }
    &_border_less {
      @extend .layout__flex_column;
      justify-content: center !important;
      flex-grow: 1;
      height: auto;
      border: none;
      padding: 0;

      @include media(sm) {
        min-height: 238px;
      }
    }

    &_row {
      @extend .layout__flex_row;
      align-items: center;
      gap: 24px;

      h4 {
        @extend .heading__item;
      }

      p {
        font-size: $text-base !important;
        line-height: 22px !important;
      }
    }
  }

  .body__item_text {
    @extend .layout__flex_column;
    height: 100%;
    gap: 8px;
    justify-content: space-between;

    &_main_content {
      @extend .layout__flex_column;
      gap: 8px;
    }

    &_two_column {
      gap: 16px;
    }
  }

  .separator {
    border-top-width: 4px;
    border-top-style: solid;
    margin: 24px 0 0;
    padding: 24px 0 0;

    @include media(sm) {
      margin: 0 0 0 24px;
      padding: 0 0 0 24px;
      border-top-width: 0;
      border-left-width: 4px;
      border-left-style: solid;
    }

    @include media(lg) {
      margin: 0 0 0 91px;
      padding: 0 0 0 91px;
      border-top-width: 0;
      border-left-width: 4px;
      border-left-style: solid;
    }
  }

  .body__figure_single {
    position: relative;
    width: 64px;
    overflow: hidden;
    background-color: $gray-50;
    border-radius: 100%;
    height: 64px;
    margin-bottom: 16px;

    img {
      border-radius: 100%;
      height: auto;
    }

    i {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      font-size: $text-base;
      color: $gray-300;
    }
  }

  .body__icon_action {
    @extend .body__figure_single;
    width: 32px;
    height: 32px;
    margin-bottom: 0;

    i {
      color: $link-inf-product;
    }
  }

  .body__contents_two_columns_title {
    @extend .layout__flex_column;
    position: relative;
    width: 100%;
    align-items: center;
    padding-top: 100%;
    min-height: 336px;
    padding: 24px;

    @include media(sm) {
      flex-direction: row;
      padding: 48px;
    }

    &_border {
      border-radius: $border-radius-md;
      justify-content: center;
    }
  }

  a:not(.body__item_link) {
    width: fit-content;
    color: $label-btn-link;
    padding: 0;
    font-size: $text-sm;
    line-height: 18px;
    font-weight: 600;
    margin-top: 14px;
    order: 5;
  }

  .body__button_primary {
    display: block;
    text-align: center;
    border-radius: $border-radius-btn;
    padding: 11px 4px !important;
    width: 100%;
    max-width: 316px;
    font-weight: 500;
    background: $btn-primary;
    color: $label-btn-primary;

    @include media(sm) {
      padding: 15px 16px !important;
    }
  }
}
