@import '../../styles/variables';
@import '../../styles/mixin';

.placeholder__page_ecommerce {
  display: flex;
  flex-direction: column;
  row-gap: 20px;

  @include media(md) {
    flex-direction: row;
    column-gap: 20px;
  }
  .placeholder__container {
    display: flex;
    flex-direction: column;
    flex: 1;

    @media screen and (max-width: 480px) {
      margin: 0;
      padding: 0;
    }

    .placeholder__banner {
      padding: 0 16px;
      background-color: $special-placeholder-color !important;
      height: 174px;
      width: 100%;
      border-radius: 4px;
      margin: 0 0 20px;
    }

    .placeholder__product_container {
      flex-grow: 1;
      flex-direction: column;
      padding-bottom: 4rem;

      .placeholder__product_list {
        display: grid;
        grid-column: 1 / -1;
        grid-template-columns: repeat(2, minmax(0, 1fr));
        align-items: center;
        justify-content: center;
        justify-items: auto;
        width: 100;
        row-gap: 30px;

        @media screen and (min-width: 540px) {
          grid-template-columns: repeat(3, minmax(0, 1fr));
        }

        @include media(sm) {
          grid-template-columns: repeat(auto-fill, minmax(160px, 1fr));
          grid-row-gap: 24px;
        }


        @include media(md) {
          grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
        }

        .placeholder_product {
          max-width: 236px;
          //height: 386px;
          width: 100%;
        }
      }

      .placeholder__pagination {
        margin: 0 auto;
        display: flex;
        align-items: center;
        margin-top: 4rem;
        column-gap: 16px;
        max-width: 360px;

        .placeholder__container_pages {
          display: flex;
          column-gap: 16px;
          flex-grow: 1;

          .placeholder__page {
            height: 40px;
            width: 40px;
            border-radius: 50%;
          }
        }

        .placeholder__actions {
          height: 16px;
          width: 30px;
          border-radius: 16px;
        }
      }
    }
  }
}

.placeholder__page_product_detail {
  padding-top: 48px;
  padding-bottom: 48px;
  position: relative;

  .placeholder__product_detail {
    display: grid;
    row-gap: 40px;
    margin: 40px 0;

    @include media(sm) {
      margin-top: 40px;
    }

    @include media(md) {
      column-gap: 40px;
      grid-template-columns: repeat(2, minmax(0, 1fr));
    }

    @include media(lg) {
      grid-template-columns: minmax(0, 1fr) minmax(0, 359px);
    }

    @include media(xl) {
      grid-template-columns: minmax(0, 1fr) minmax(0, 379px);
      column-gap: 32px;
    }

    @include media(2xl) {
      grid-template-columns: minmax(0, 1fr) minmax(0, 479px);
      column-gap: 78px;
    }

    @include media(3xl) {
      grid-template-columns: minmax(0, 1fr) minmax(0, 623px);
      column-gap: 160px;
    }

    .placeholder__product_asset {
      .placeholder__product_asset_container {
        @include media(lg) {
          display: grid;
          grid-template-columns: 1fr 1fr;
          column-gap: 20px;
        }
      }

      .placeholder__dot_container {
        margin-top: 24px;
        display: flex;
        column-gap: 18px;
        justify-content: center;

        .placeholder__figure {
          max-width: 50px;
          padding: 5px;

          @include media(xl) {
            max-width: 90px;
          }
        }
      }
    }

    .placeholder__product_info {
      padding: 0 10px;

      .placeholder__product_brand {
        height: 12px;
        width: 40%;
        margin-bottom: 5px;
      }

      .placeholder__product_name {
        width: 100%;
        height: 60px;
        margin-bottom: 16px;
      }

      .placeholder__product_category {
        width: 25%;
        height: 24px;
        margin-bottom: 16px;
      }

      .placeholder__product_description_title {
        height: 20px;
        width: 55%;
        margin-bottom: 10px;
        background-color: $placeholder-bg-100;
      }

      .placeholder__product_social_title {
        @extend .placeholder__product_description_title;
        width: 20%;
      }

      .placeholder__social_item {
        width: 30px;
        height: 30px;
        border-radius: 30%;
      }

      .placeholder__text {
        height: 12px;
        width: 100%;
        margin-bottom: 3px;
      }

      .placeholder__product_actions {
        display: flex;
        align-items: center;
        column-gap: 24px;
        padding: 25px 10px;
        border-top: 1px solid $gray-50;
        border-bottom: 1px solid $gray-50;
        margin-bottom: 30px;

        .placeholder__btn {
          height: 57px;
          max-width: 170px;
          flex-grow: 1;
          border-radius: 30px;
        }

        .placeholder__price {
          height: 38px;
          width: 40%;
          max-width: 80px;
          background-color: $placeholder-bg-300;
        }

        .placeholder__favorite {
          height: 45px;
          min-width: 45px;
          max-width: 45px;
          border-radius: 50%;
        }
      }

      .placeholder__product_about_title {
        @extend .placeholder__product_description_title;
        width: 30%;
      }

      .placeholder__product_about {
        display: flex;
        flex-direction: column;
        row-gap: 16px;
      }
    }

    .placeholder__text:nth-last-of-type(6) {
      width: 80%;
    }

    .placeholder__text:nth-last-of-type(5) {
      width: 92%;
    }

    .placeholder__text:nth-last-of-type(4) {
      width: 96%;
    }

    .placeholder__text:nth-last-of-type(3) {
      width: 87%;
    }

    .placeholder__text:nth-last-of-type(2) {
      width: 67%;
    }

    .placeholder__text:nth-last-of-type(1) {
      width: 43%;
      margin-bottom: 30px;
    }

    .placeholder__figure,
    .placeholder__figure_secondary {
      aspect-ratio: 1/1;
      padding: 20px;
      width: 100%;
      height: 100%;
      background-color: $gray-50;
    }

    .placeholder__figure_secondary {
      display: none;

      @include media(lg) {
        display: inline-block;
      }
    }
  }

  .placeholder {
    position: relative;
    width: 100%;
    max-width: 536px;
    height: 100%;
  }

  .placeholder__products_related {
    .placeholder__related_header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 40px;

      .placeholder__related_title {
        width: 60%;
        max-width: 320px;
        height: 52px;
      }

      .placeholder__related_actions {
        width: 80px;
        height: 40px;
        border-radius: 30px;
      }
    }

    .placeholder__related_container {
      display: flex;
      column-gap: 20px;
      overflow: hidden;

      grid-template-columns: repeat(auto-fill, minmax(236px, 1fr));

      > div {
        min-width: 236px;
      }
    }
  }

  .placeholder__product_rating,
  .placeholder__product_social {
    display: flex;
    column-gap: 16px;
    margin-bottom: 50px;
  }

  .placeholder__start_small {
    width: 20px;
    height: 20px;
    border-radius: 30%;
    background-color: $placeholder-bg-300;
  }
}
