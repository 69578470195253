@import '../../styles/variables';
@import '../../styles/mixin';

.method {
  align-items: flex-start;
  background: $white;
  border: 0.5px solid $gray-200;
  border-radius: $border-radius;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  height: 49px;
  margin: 20px 0;
  padding: 4px;
  width: 100%;
  max-width: 384px;

  .secondary {
    display: flex;
    flex-direction: row;
    padding: 8px 24px;
    width: 188px;
    height: 40px;
    background: $btn-primary;
    border-radius: $border-radius !important;
    justify-content: center;

    span {
      font-weight: 500;
      font-size: $text-base;
      line-height: 24px;
      display: flex;
      color: $label-btn-primary;
    }
  }
  .primary {
    display: flex;
    flex-direction: row;
    padding: 8px 24px;
    width: 188px;
    height: 40px;
    border-radius: $border-radius !important;
    justify-content: center;

    span {
      font-weight: 500;
      font-size: $text-base;
      line-height: 24px;
      display: flex;
      color: $label-btn-secondary;
    }
  }
}
