@import '../../styles/variables';
@import '../../styles/mixin';

.fallback_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 450px;
  margin-top: 20vh;

  .fallback_info {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 1rem;
    gap: 1rem;

    h1 {
      font-size: x-large;
      font-weight: 600;
    }

    p {
      font-size: $text-base;
      color: $alert-message;
    }
  }
  button {
    background: $btn-primary;
    border-radius: $border-radius-btn;
    color: $label-btn-primary;
    font-size: $text-sm;
    font-weight: 500;
    padding: 11px 30px;
    text-align: center;
    text-transform: uppercase;
  }
}
