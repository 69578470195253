@import '../../styles/variables';
@import '../../styles/mixin';

.item-header-button {
  align-items: center;
  border: 1px solid $gray-100;
  border-radius: $border-radius;
  display: flex;
  flex-direction: row;
  gap: 8px;
  height: 48px;
  margin-right: 15px;
  max-width: 408px;
  padding: 6px 8px;
  width: 95%;

  @include media(lg) {
    margin-right: 0;
  }

  .button-content {
    align-items: center;
    display: grid;
    grid-template-columns: 1fr 14fr 1fr;

    @include media(xl) {
      grid-template-columns: 3fr 14fr 2fr;
    }
  }
}

.header-info-form {
  $form-width: 350px;
  background: $white;
  border-radius: $border-radius;
  box-shadow: 0px 0px 12px rgba(186, 186, 186, 0.3);
  display: flex;
  flex-direction: column;
  left: 50%;
  margin: 5px $space-x $space-x;
  max-height: calc(100vh - 171px);
  max-width: 408px;
  padding: 16px 16px 24px;
  position: absolute;
  transform: translateY(54px) translateX(calc(-50% - 16px));
  transition: $transition-all;
  width: calc(100% - #{$space-x * 2});
  z-index: 20;
  @include media(sm) {
    transform: translateY(54px) translateX(-315px);
  }
  @include media(md) {
    transform: translateY(54px) translateX(-276px);
  }
  @include media(lg) {
    transform: translateY(54px) translateX(-247px);
  }
  @include media(xl) {
    transform: translateY(54px) translateX(-122px);
  }
  @include media(2xl) {
    transform: translateY(54px) translateX(16px);
  }

  .description {
    display: flex;
    flex-direction: column;

    .description-header {
      align-items: center;
      align-self: stretch;
      color: $gray-600;
      display: flex;
      flex: none;
      flex-grow: 0;
      font-size: $text-sm;
      line-height: 18px;
      order: 0;
      font-weight: 500;
    }
    .description-body {
      align-items: center;
      align-self: stretch;
      color: $gray-600;
      display: flex;
      flex: none;
      flex-grow: 0;
      font-size: $text-sm;
      font-weight: 400;
      line-height: 18px;
      order: 1;
      padding-top: 5px;
    }
  }
}
