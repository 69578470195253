@import '../../styles/variables';
@import '../../styles/mixin';

.info {
  overflow: hidden;
  padding: 0 8px;
  text-overflow: ellipsis;

  @include media(xl) {
    padding: 0;
    width: 326px;
  }
  &.info_is-invalid {
    .icon {
      color: $errors;
    }
  }
  .address {
    font-weight: 400;
    font-size: $text-sm;
    line-height: 18px;
    display: flex;
    align-items: center;
    color: $primary;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .icon {
    color: $success;
  }

  span {
    display: block;
    text-align: left;
    font-size: $text-sm;
  }
}

.method {
  font-weight: 500;
  font-size: $text-sm;
  line-height: 18px;
  display: flex;
  align-items: center;
  color: $black;
}
