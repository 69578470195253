@import '../../styles/variables';
@import '../../styles/mixin';

.layout_colum {
  display: flex;
  flex-direction: column;
}

.location__info {
  padding-top: 24px;
  padding-bottom: 24px;
  width: 100%;

  @include media(sm){
    padding-top: 48px;
    padding-bottom: 48px;
  }

  &_grid_container {
    @extend .layout_colum;
    gap: 20px;

    @include media(sm){
      gap: 32px;
    }

    @include media(lg) {
      display: grid;
      grid-template-columns: repeat(2, minmax(0, 1fr));
      grid-row: 1fr 1fr;
    }

    @include media(2xl) {
      column-gap: 64px;
    
    }
  } 
   
  &_map {
     @extend .layout_colum;
     justify-content: stretch;
     height: 100%;
     width: 100%;
     gap: 32px;

    &_container {
      height: 200px !important;
      max-height: -webkit-fill-available !important;
      border-radius: $store-locator-info-border-radius;

      @include media(sm){
        flex-grow: 1;
        height: 368px !important;
      }
    }

    div[class~='gm-style-iw-chr']{
      display: none;
    }
  }

  &_description {
    display: none;

    @include media(sm){
       display: block;
    }
  }

  &_content {
    @extend .layout_colum;
    gap: 20px;

    @include media(sm){
      gap: 32px;
    }
  }

  &_body {
    @extend .layout_colum;
    gap: 16px
  }

  &_list {
    @extend .layout_colum;
    gap: 12px;

    @include media(sm){
      display: grid;
      grid-template-columns: repeat(2, minmax(0, 1fr));
    }

   /*  li:last-child {
      grid-column: 1 / -1;
    } */

    &_item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 2rem;
      padding: 16px;
      border-radius: $store-locator-info-border-radius;
      background-color: $store-locator-info-background;
      font-style: normal !important;

      h5 {
        font-size: $text-base;
        font-weight: 500;
        line-height: 1.25rem;
        letter-spacing: 0.01px;
        color: $store-locator-info-title;
      }

      h5::first-letter {
        text-transform: uppercase;
      }

      p, span, a {
        font-size: $text-sm;
        color: $store-locator-info-text;
        font-weight: 400;
        line-height: 1rem;
        letter-spacing: 0.01px;
      }

      .grid__element_info_socials {
        font-size: $text-lg;
      }

      .message__closed {
        color: $store-status-closed-text-color !important;
      }
    }

    &_item_contact {
      @extend .location__info_list_item;
      flex-direction: column;
      gap: 4px;
      align-items: flex-start;
      text-align: left;
      
    }

    &_empty {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      padding: 32px 16px;
      width: 100%;

      @include media(sm){
        grid-column: 1 / -1
      }
    }
  }
}