@import '../../styles/variables';
@import '../../styles/mixin';

@function get-border-color($top-color, $bottom-color) {
  @return if($bottom-color == none, $top-color, $bottom-color);
}

.megamenu {
  background: $background-megamenu;
  backdrop-filter: $megamenu-backdrop-filter;
  border-top: 0.5px solid $mega-border-color;
  border-bottom: 0.5px solid get-border-color($mega-border-color, $mega-border-bottom-color);
  left: 0;
  overflow-y: auto;
  padding-bottom: 56px;
  padding-top: 48px;
  pointer-events: initial;
  position: fixed;
  top: var(--megamenu-top);
  transition: $transition-all;
  user-select: none;
  width: 100%;
  will-change: transform;
  z-index: 2;

  &__border_with_spacing {
    $spacing: calc($header-logo-max-width + 20px);
    border-top: 1px solid transparent;
    background-image: linear-gradient(
      to right,
      $mega-border-color 0%,
      $mega-border-color calc(50% - #{$spacing} / 2),
      transparent calc(50% - #{$spacing} / 2),
      transparent calc(50% + #{$spacing} / 2),
      $mega-border-color calc(50% + #{$spacing} / 2),
      $mega-border-color 100%
    );
    background-size: 100% 1px;
    background-repeat: no-repeat;
    background-position: top;
  }

  &_single {
    position: absolute;
    top: 99%;
    left: calc(-1 * $mega-single-border-radius);
    width: fit-content;
    min-width: 220px;
    z-index: 2;

    &__content {
      margin-top: 8px;
      background: $background-megamenu;
      backdrop-filter: $megamenu-backdrop-filter;
      border: 0.5px solid $mega-single-border-color;
      border-radius: $mega-single-border-radius;
      display: flex;
      flex-direction: column !important;
      padding: 24px !important;
      row-gap: 10px;
      width: 100%;

      li {
        text-align: left;
        height: 30px !important;
        width: max-content;
        border: 0;

        &:hover {
          border-bottom: 4px solid $mega-link-hover;
        }

        a {
          color: $mega-text-color !important;
          height: 24px !important;
        }
      }
    }
  }

  &__container {
    display: grid;
    grid-template-areas: 'group-link0 group-link1 group-link2 group-picture0';
    grid-template-columns: 2fr 2fr 2fr 4fr;

    &__doble_pictures_section {
      grid-template-areas: 'group-link0 group-picture0 group-picture1';
      grid-template-columns: 1fr 3fr 3fr;

      @include media(xl) {
        grid-template-columns: 1fr 2fr 2fr;
      }
    }

    .title {
      font-weight: 500;
      color: $mega-title-color;
      margin-bottom: 16px;
      text-transform: uppercase;
      text-align: left;
    }

    .links_content {
      display: flex;
      flex-direction: column;
      padding-right: 20px;
      row-gap: 10px;

      @include media(2xl) {
        padding-right: 80px;
      }

      a {
        line-height: 24px;
        color: $mega-text-color !important;
      }

      li {
        height: 30px !important;
        width: max-content;
      }
    }

    .pictures {
      margin-left: auto;
      max-width: 392px;

      @include media(2xl) {
        max-width: 528px;
      }

      @include media(3xl) {
        max-width: 664px;
      }

      .pictures_content {
        display: flex;
        flex-wrap: wrap;
        gap: 16px;

        &__figure {
          > span {
            border-radius: $border-radius;
          }
        }
      }
    }
  }
}
