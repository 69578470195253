@import '../../styles/variables';
@import '../../styles/mixin';

.input_container {
  display: flex;
  flex-direction: column;
  width: 100%;

  .input {
    border-radius: $border-radius-input;
    display: block;
    position: relative;
    display: flex;
    align-items: center;
    text-align: left;
    height: 56px;

    &__small {
      height: 48px;

      input::placeholder {
        font-size: $text-sm !important;
      }
    }

    &__type_text,
    &__type_search {
      background: $input-bg;
      border: 1px solid $input-border-color;
      overflow: hidden;

      &:hover {
        border: 1px solid $input-border-color-hover;
      }

      &:focus-within {
        border: 1px solid $input-border-color-activate;

        .input__label {
          color: $input-color-label-activate !important;
        }

        input::placeholder {
          color: $input-color-label-activate;
        }

        i {
          color: $input-color-label-activate;
        }

        button {
          i {
            opacity: 1;
          }
        }
      }

      &.input__invalid {
        border-color: $input-invalid-color;
      }

      &.input__disabled {
        background: $input-disabled-bg;
      }
    }

    &__standard {
      border-radius: 0;
      border: 0;
      border-bottom: 1px solid $input-border-color;

      &:hover {
        border-bottom: 1px solid $input-border-color-hover;
      }

      &:focus-within {
        border: 0 !important;
        border-bottom: 1px solid $input-border-color-activate;
      }
    }

    &__footer {
      background: $input-background-footer;
      border-radius: $border-radius-input !important;
      border: 1px solid $input-border-color-footer !important;

      &:focus-within {
        border: 1px solid $input-border-color-footer-activate !important;

        &:hover {
          border: 1px solid $input-border-color-footer-hover !important;
        }

        input::placeholder {
          color: $input-placeholder-color-activate !important;
        }

        .input__addortment {         
          color: $input-placeholder-color-activate !important;

          svg {
            * {
              stroke: $input-placeholder-color-activate !important;
            }
          }
        }
      }

      &_standard {
        border-radius: 0 !important;
        border: 0 !important;
        border-bottom: 1px solid $input-border-color-footer !important;

        &:focus-within {
          border: 0 !important;
          border-bottom: 1px solid $input-border-color-footer-activate !important;

          &:hover {
            border: 0 !important;
            border-bottom: 1px solid $input-border-color-footer-hover !important;
          }
        }
      }

      .input__control {
        line-height: 40px;
        padding: 0px 16px;
        color: $input-text-color !important;
      }

      input::placeholder {
        color: $input-placeholder-color !important;
      }

      .input__addortment {
        color: $input-placeholder-color !important;

        svg {
          * {
            stroke: $input-placeholder-color !important;
          }
        }
      }
    }

    &:not(.input__type_text) {

      &,
      .input__label,
      .input__control {
        cursor: pointer;
      }
    }

    &__disabled {

      &,
      .input__label,
      .input__control {
        cursor: not-allowed !important;
      }
    }

    /* Label styles */
    .input__label {
      position: absolute;
      width: 100%;
      background: transparent;
      top: -1px;

      &_search {
        padding-left: 20px;
      }

      &_checkbox {
        position: relative;
        margin-left: 30px;
        font-size: $text-base;
      }

      &_icon {
        width: calc(100% - 54px);
        padding-left: 0 !important;
        margin-left: 54px;
      }

      &_small {
        font-size: 10px !important;
        padding-top: 6px !important;
      }
    }

    &.input__type_text .input__label {
      font-size: $text-xs;
      color: $input-color-label;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      padding: 10px 16px 0;
    }

    /* Control styles */
    .input__control {
      width: 100%;
      color: $input-color;
      padding: 10px 18px !important;
      background: transparent;
      font-size: $text-base;
      line-height: 36px;

      &_pressed {
        padding: 18px 16px 0 !important;
      }

      &_icon {
        padding: 10px 16px 10px 0 !important;
      }

      &_btn {
        padding: 10px 54px 10px 16px !important;
      }

      &_btn_icon {
        padding: 10px 54px 10px 0px !important;
      }

      &_icon_pressed {
        padding: 18px 16px 0 0 !important;
      }

      &_btn_pressed {
        padding: 18px 54px 0 16px !important;
      }

      &_btn_icon_pressed {
        padding: 18px 54px 0 0 !important;
      }

      &[type='search'] {
        background: transparent;
        color: $input-color;
      }

      &[type='search']::-webkit-search-cancel-button {
        -webkit-appearance: none;
        -webkit-user-modify: read-write !important;
        border-left: 1px solid $input-border-color;
        padding-left: 10px;
        width: 18px;
        font-size: 18px;
        position: absolute;
        top: 19px;
        right: 10px;
        content: url("data:image/svg+xml;charset=UTF-8,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M4 4L20 20M20 4L4 20' stroke='%23" + $input-icon-color + "' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3e%3c/svg%3e");
        cursor: pointer;

        &:hover {
          content: url("data:image/svg+xml;charset=UTF-8,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M4 4L20 20M20 4L4 20' stroke='%23" + $input-icon-color-activate + "' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3e%3c/svg%3e");
        }
      }

      &[type='checkbox'] {
        padding: 0 !important;
        appearance: none;
        border: 2px solid $input-ckek-border-color;
        border-radius: $border-radius-sm;
        background: $input-ckek-background-color;
        font-size: revert;
        width: 1.15rem;
        height: 1.15rem;
        position: absolute;

        .input__label {
          position: relative;
        }

        &::before {
          content: '';
          position: absolute;
          transform: scale(0);
          transition: 120ms transform ease-in-out;
          display: none;
          left: 50%;
          top: 38%;
          transform: translate(-50%, -50%) rotate(45deg);
          //transform: rotate(41deg) translate(-90%, -130%);
          height: 10px;
          width: 5px;
          border-bottom: 2px solid $input-check-select;
          border-right: 2px solid $input-check-select;
        }

        &:checked {
          border-color: $input-check-color;
          background: $input-ckek-background-select;

          &::before {
            display: inline-block;
          }
        }

        &:focus {
          border-color: $input-check-color;
        }

        &:disabled {
          background: $input-disabled-bg;
        }
      }

      &[type='radio'] {
        padding: 0;
        appearance: none;
        -webkit-appearance: none;
        -moz-appearance: none;
        border-radius: 50%;
        border: 1px solid $input-ckek-border-color;
        background: $input-ckek-background-color;
        height: 20px;
        width: 20px;
        top: 4px;
        position: absolute;

        &:checked {
          border-color: $input-check-color;
          background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-10 -10 20 20'%3e%3ccircle r='6' fill='%23" + $input-check-radio-button + "' /%3e%3c/svg%3e");
        }
      }

      &[type='date']::-webkit-calendar-picker-indicator {
        -webkit-appearance: none;
        -webkit-user-modify: read-write !important;
        border-left: 1px solid $input-border-color;
        padding-left: 10px;
        width: 22px;
        font-size: 22px;
        position: absolute;
        top: 14px;
        right: 10px;
        cursor: pointer;
        background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M3 10H21M7 3V5M17 3V5M6.2 21H17.8C18.9201 21 19.4802 21 19.908 20.782C20.2843 20.5903 20.5903 20.2843 20.782 19.908C21 19.4802 21 18.9201 21 17.8V8.2C21 7.07989 21 6.51984 20.782 6.09202C20.5903 5.71569 20.2843 5.40973 19.908 5.21799C19.4802 5 18.9201 5 17.8 5H6.2C5.0799 5 4.51984 5 4.09202 5.21799C3.71569 5.40973 3.40973 5.71569 3.21799 6.09202C3 6.51984 3 7.07989 3 8.2V17.8C3 18.9201 3 19.4802 3.21799 19.908C3.40973 20.2843 3.71569 20.5903 4.09202 20.782C4.51984 21 5.07989 21 6.2 21Z' stroke='%23070C15' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3e%3c/svg%3e");

        &:hover {
          background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M3 10H21M7 3V5M17 3V5M6.2 21H17.8C18.9201 21 19.4802 21 19.908 20.782C20.2843 20.5903 20.5903 20.2843 20.782 19.908C21 19.4802 21 18.9201 21 17.8V8.2C21 7.07989 21 6.51984 20.782 6.09202C20.5903 5.71569 20.2843 5.40973 19.908 5.21799C19.4802 5 18.9201 5 17.8 5H6.2C5.0799 5 4.51984 5 4.09202 5.21799C3.71569 5.40973 3.40973 5.71569 3.21799 6.09202C3 6.51984 3 7.07989 3 8.2V17.8C3 18.9201 3 19.4802 3.21799 19.908C3.40973 20.2843 3.71569 20.5903 4.09202 20.782C4.51984 21 5.07989 21 6.2 21Z' stroke='%23" + $input-icon-color-activate + "' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3e%3c/svg%3e");
        }
      }

      &[type='date']:before {
        content: attr(placeholder) !important;
      }

      &[type='date']:focus:before,
      &[type='date']:valid:before {
        content: '';
      }

      &:focus {
        outline: 0;
      }

      &_small {
        font-size: $text-base;
        padding: 10px 56px 10px 18px !important;
        line-height: 20px !important;

        &[type='search']::-webkit-search-cancel-button {
          width: 16px;
          font-size: $text-base;
          top: 14px;
          right: 10px;
          content: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M3 3L13 13M13 3L3 13' stroke='%23" + $input-icon-color-activate + "' stroke-width='1.6' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
          cursor: pointer;

          &:hover,
          &:focus {
            content: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M3 3L13 13M13 3L3 13' stroke='%23" + $input-icon-color-activate + "' stroke-width='1.6' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
          }
        }
      }

      &_pressed_small {
        padding: 18px 16px 0 !important;
      }

      &_icon_small {
        padding: 11px 16px 11px 0 !important;
      }

      &_btn_small {
        padding: 11px 54px 11px 16px !important;
      }

      &_btn_icon_small {
        padding: 11px 54px 11px 0px !important;
      }

      &_icon_pressed_small {
        padding: 18px 54px 4px 0 !important;
      }

      &_btn_pressed_small {
        padding: 18px 54px 4px 16px !important;
      }

      &_btn_icon_pressed_small {
        padding: 18px 54px 4px 0 !important;
      }
    }

    &.input__invalid .input__control {

      &[type='radio'],
      &[type='checkbox'] {
        border-color: $input-invalid-color;
      }
    }
  }

  .input__btn {
    position: absolute;
    width: 30px;
    height: 28px;
    right: 11px;
    top: 14px;
    padding: 0;
    border-radius: 0;
    border-left: 1px solid unquote('##{$input-icon-color}');
    padding-left: 10px;

    svg {
      * {
        stroke: add-hash-if-needed($input-icon-color);
      }
    }

    &:hover, &:focus, &:active {
      svg {
        * {
          stroke: add-hash-if-needed($input-icon-color-activate);
        }
      }

      opacity: 1;
    }

    &_small {
      top: 6px !important;
    }
  }

  .input__addortment {
    font-size: $text-xl;
    width: 24px;
    margin: 0 16px;
    color: $input-color-label;
    opacity: 1;
    cursor: unset;

    svg {
      * {
        stroke: $input-color-label;
      }
    }

    &:hover, &:focus, &:active {
      color: $input-color-label-activate;

      svg {
        * {
          stroke: $input-color-label-activate;
        }
      }
    }
  }

  .input__textarea {
    height: auto;
    overflow-y: auto;
    border-radius: $border-radius !important;

    .input__control {
      line-height: 24px;

      &_pressed {
        padding: 0 16px 18px !important;
        margin-top: 28px;
      }
    }

    textarea {
      &::-webkit-scrollbar {
        width: 6px;
        background: $gray-50;
      }

      &::-webkit-scrollbar-thumb {
        background-color: $gray-100;
        border-radius: 8px;
      }

      &:hover {
        &::-webkit-scrollbar-thumb {
          background-color: $gray-200;
        }
      }
    }

    textarea::placeholder {
      color: $input-color-label-activate !important;
      opacity: 50%;
    }
  }

  input::placeholder {
    font-size: $text-base;
    color: $input-color-label;
    opacity: 50%;
  }

  .input__feedback {
    font-size: 80%;
    text-align: left;
    color: $input-invalid-color;
    margin-top: 4px;
  }
}