@import '../../styles/variables';
@import '../../styles/mixin';

.mapslice {

  &__container {
    position: relative;
    overflow: hidden;
    height: 520px;

    @media screen and (max-width: 768px) {
      padding-left: 0;
      padding-right: 0;
      margin: 0;
      max-width: none !important;
    }
   
    &_full_width {
      padding-left: 0;
      padding-right: 0;
      margin: 0;
      max-width: none !important;
    }
  }

  &__map {
    position: relative;
    overflow: hidden;
    height: 100%;

    div[class~='gm-style-iw-chr'] {
      display: none;
    }
  }

  .content {
    display: flex;
    align-items: stretch;
    height: auto;
    width: 100%;
    max-height: fit-content;
    padding: 24px 20px;
    margin: 0 auto;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;

    @include media(md) {
      margin-left: 20px;
      padding: 36px;
      max-width: 36%;
      max-height: -webkit-fill-available;
      bottom: 0;
    }

    @include media(xl) {
      padding: 48px;
    }

    @include media(3xl) {
      padding: 96px;
    }

    &__full {
      margin-left: 0;

      @include media(md) {
        max-width: 43%;
        //justify-content: flex-end !important;
      }  
      
      @include media(lg) {
        padding-left: 3.20%;
        //justify-content: flex-end !important;
      }
    }

    &__description {
      position: relative;
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      height: fit-content;
      max-height: 100%;
      overflow: auto;
      width: 100%;
      row-gap: 12px;
      z-index: 2;

      @include media(md){
        row-gap: 16px;
      }

      &::-webkit-scrollbar {
        width: 4px;
      }

      &::-webkit-scrollbar-thumb {
        background-color: $gray-200;
        border-radius: 8px;
      }

      &__action {
        margin-top: 4px;
        display: flex;
        align-items: center;
        font-size: $text-sm;
        padding: 9px 14px;
        height: 36px;
        width: fit-content;
        min-width: 100px;
        background-color: var(--cta-background-color);
        color: var(--cta-text-color);
        border: var(--border-style);
        border-radius: $border-radius-btn;
        text-align: center;
        font-weight: 500;
        transition: all 0.3s ease;

        &:hover {
          background-color: var(--cta-background-color-hover);
          color: var(--cta-text-color-hover);
          border: var(--border-style-hover);
        }

        @include media(md){
          margin-top: 16px;   
        }

        @include media(lg) {
          height: 48px;
          font-size: $text-base;
          line-height: 22.39px;
          min-width: 140px;
          padding: 8px 16px;
        }
      }

      &__descrip {  
        width: 100%; 

        * {
          font-weight: 400;
          font-size: 16px;
          line-height: 20px;
          letter-spacing: 0.01em;

          @media screen and (max-width: 520px) {
            line-height: 18px;
            font-size: $text-sm;
          }
        }   
      }

      &__heading {
        font-weight: 700;
        font-size: $text-base !important;
        line-height: 24px !important;

        @include media(xl) {
          font-size: $text-xl !important;
          line-height: 28px !important;
        }
      }

      &__header {
        font-weight: 700;
        font-size: 2rem !important;
        line-height: 40px !important;
        letter-spacing: -0.01em;

        @media screen and (max-width: 520px) {
          br {
            display: none;
          }
        }

        @include media(xl) {
          font-size: 3rem !important;
          line-height: 60px !important;
        }
      }

      &_shadow {
        * {
          text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25) !important;
        }
       
      }
    }
  }
}
