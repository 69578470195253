@import '../../styles/variables';
@import '../../styles/mixin';

.map-multilocation {
  position: relative;
  height: 767px;

  .default_map {
    object-fit: cover;
  }

  .empty_locations_square {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: $white;
    background: $store-locator-placeholder-map-background;
    padding: 0.25rem 1rem;
    width: 240px;
    height: 180px;
    text-align: center;
    border-radius: $store-locator-info-border-radius;

    @include media(lg) {
      width: 340px;
      height: 180px;
    }

    .search_icon {
      color: #f2dab2;
      font-size: $text-3xl;
      margin-bottom: 1rem;

      @include media(lg){
        font-size: $text-4xl;        
      }
    }

    .title {
      font-size: $text-base;     
      font-weight: 700;
      margin-bottom: 0.5rem;

      @include media(lg){
        font-size: $text-lg;
      }
    }

    .message {
      font-size: $text-xs;
  

      @include media(lg){
        font-size: $text-sm;
      }
    }
  }

  .map__pin_title {
    max-width: 100px;
  }
}
