@import '../../styles/variables';
@import '../../styles/mixin';

.logo_text {
  display: flex;
  align-items: center;

  &__container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 40px 0 0;
    row-gap: 20px;
  }

  &_background {
    padding-bottom: $logo-text-padding-bottom;
  }

  h2 {
    order: 2;
    text-align: center;
  }

  .logo_description {
    order: 3;
    font-size: $text-base;
    margin: 0 auto;
    padding: 0 15px;
    width: 100%;

    @include media(sm) {
      padding: 0 20px;
    }
  }

  span {
    margin: auto !important;
  }

  .logo_container {
    width: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
  }
}
