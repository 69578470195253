@import '../../styles/variables';
@import '../../styles/mixin';

.feature_text__section {
  display: flex;
  align-items: center;
}

.container {
  .headding {
    font-size: calc(1.1rem + 1.5vw);
    line-height: calc(1.9rem + 1.5vw);
    font-weight: 700;
    color: $gray-500;

    @include media(2xl) {
      font-size: 40px;
    }
  }

  .headding__small {
    font-size: $text-2xl !important;
    line-height: 32px !important;
    font-weight: 700;
    color: $gray-500;
  }

  .heading__item {
    font-size: $text-xl !important;
    line-height: 26px !important;
  }

  .text__heading {
    font-size: $text-sm !important;
    font-weight: 600;
    color: $gray-300;
    line-height: 18px;
    letter-spacing: 2%;
  }

  .heading__large {
    font-size: calc(1.4rem + 1.5vw) !important;
    font-weight: 700;
    color: $gray-500;

    @include media(2xl) {
      font-size: $text-5xl !important;
      line-height: 62px;
    }
  }

  .layout__flex_column {
    display: flex;
    flex-direction: column;
  }

  .layout__flex_row {
    display: flex;
    flex-direction: row;
  }

  .content {
    @extend .layout__flex_column;
    align-items: center;
    text-align: center;
    padding: 0 20px;
    gap: 48px;

    @media screen and (max-width: 420px) {
      padding: 0 5px;
    }

    @include media(sm) {
      padding: 0 40px;
    }

    @include media(xl) {
      padding: 0 90px;
    }

    @include media(2xl) {
      padding: 0 107px;
    }

    @include media(3xl) {
      padding: 0 144px;
    }
  }

  .content__two_columns {
    @extend .content;

    @include media(xl) {
      padding: 64px 146px;
    }

    @include media(2xl) {
      padding: 64px 176px;
    }

    @include media(3xl) {
      padding: 86px 236px;
    }
  }

  .content__four_column {
    @extend .content;
    gap: 62px;
  }

  .content__heading_and_feature {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(1, minmax(0, 1fr));
    gap: 24px;

    @include media(lg) {
      padding: 40px;

      h2 {
        text-align: left;
      }
      gap: 40px;
      grid-template-columns: minmax(0, 35%) 60%;
    }

    @include media(xl) {
      gap: 90px;
    }

    @include media(2xl) {
      gap: 107px;
    }

    @include media(3xl) {
      grid-template-columns: minmax(0, 35%) 65%;
      gap: 144px;
    }

    &_spacing {
      border-radius: $border-radius-md;
    }
  }

  .body__contents {
    display: grid;
    width: 100%;
    grid-template-columns: repeat(1, minmax(0, 1fr));
    gap: 24px;

    @include media(sm) {
      grid-template-columns: repeat(2, minmax(0, 1fr));
    }

    &_default {
      @include media(lg) {
        grid-template-columns: repeat(3, minmax(0, 1fr));
      }
    }

    &_two_columns {
      @include media(lg) {
        column-gap: 64px;
        row-gap: 48px;
      }

      p {
        @extend .heading__item;
      }
    }

    .card_link {
      padding: 0;
      margin: 0;

      & > * {
        height: 100%;
      }
    }

    &_four_columns {
      @include media(lg) {
        grid-template-columns: repeat(4, minmax(0, 1fr));
      }
    }
  }

  .body__contents_feature {
    display: block;
    text-align: left;

    p:not(:first-of-type) {
      margin-top: 20px;
    }

    a {
      margin-top: 0;
    }

    .body__button_primary {
      max-width: 192px;
      order: 1;
    }

    .body__button_secondary {
      @extend .body__button_primary;
      max-width: 192px !important;
      order: 2;
      background: transparent !important;
    }

    .body__actions {
      @extend .layout__flex_row;
      align-items: center;
      justify-content: center;
      margin-top: 24px;
      gap: 12px;

      @media screen and (max-width: 420px) {
        flex-direction: column;
        width: 100%;
      }

      @include media(sm) {
        justify-content: left;
        gap: 24px;
      }
    }

    &_spacing {
      padding: 8px 0 8px 28px !important;
    }
  }

  .content__text {
    @extend .layout__flex_column;
    max-width: 814px;
    width: 100%;
    align-items: center;
    text-align: center;
    margin: auto 0;
    gap: 12px;

    p {
      @extend .heading__item;
      margin-top: 4px;
    }
  }

  .body__item {
    @extend .layout__flex_column;
    align-items: flex-start;
    text-align: left;
    gap: 8px;
  }

  .body__item_card {
    @extend .body__item;
    padding-left: 26px;
    justify-content: center;
    gap: 10px;
  }

  ul,
  ol {
    @extend .layout__flex_column;
    gap: 14px;
    list-style-type: disc;

    li {
      margin-left: 30px;
    }

    ::marker {
      font-size: larger;
      color: $btn-primary;
    }
  }

  a:not(.card_link) {
    color: $label-btn-link;
    padding: 0;
    font-size: $text-sm;
    line-height: 18px;
    font-weight: 600;
    margin-top: 14px;
    order: 5;
  }

  .body__button_primary {
    display: block;
    text-align: center;
    border-radius: $border-radius-btn;
    padding: 11px 4px !important;
    width: 100%;
    max-width: 316px;
    font-weight: 500;
    background: $btn-primary;
    color: $label-btn-primary;

    @include media(sm) {
      padding: 15px 16px !important;
    }
  }
}
