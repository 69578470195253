@import '../../styles/variables';
@import '../../styles/mixin';

@function get-border-color($top-color, $bottom-color) {
  @return if($bottom-color == none, $top-color, $bottom-color);
}

.megamenu {
  background: $background-megamenu;
  backdrop-filter: $megamenu-backdrop-filter;
  border-top: 0.5px solid $mega-border-color;
  border-bottom: 0.5px solid get-border-color($mega-border-color, $mega-border-bottom-color);
  display: flex;
  left: 0;
  max-width: 100vw;
  overflow-y: auto;
  pointer-events: initial;
  position: absolute;
  top: 48px;
  transition: $transition-all;
  user-select: none;
  width: 100%;
  will-change: transform;
  z-index: 2;
  padding-top: 48px;

  .megamenu__container {
    position: relative;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    height: 100%;
    padding-bottom: 48px;

    .section-one,
    .section-two,
    .section-three,
    .section-four {
      display: flex;
      flex-direction: column;
    }

    .section-one {
      a {
        align-items: flex-start;
        color: $mega-text-color !important;
        display: flex;
        font-size: $text-base;
        font-style: normal;
        font-weight: 500;
        padding-bottom: 12px;
        text-transform: uppercase;
      }

      .button_link {
        align-items: center;
        background: $btn-primary;
        border-radius: $border-radius;
        color: $label-btn-primary;
        border: 1px solid $border-btn-primary;
        display: flex;
        font-size: $text-xs;
        font-weight: 500;
        height: 40px;
        justify-content: center;
        line-height: 18px;
        margin-top: 12px;
        max-width: 256px;
        overflow: hidden;
        padding: 11px 30px;
        text-align: center;
        text-overflow: ellipsis;
        text-transform: uppercase;
        transition: all 0.25s ease-in;
        white-space: nowrap;
        width: 84%;

        &:not(:disabled):hover {
          background: $btn-primary-hover;
          color: $label-btn-primary-hover;
          border: 1px solid $border-btn-primary-hover;
        }

        &:not(:disabled):focus {
          background: $btn-primary-pressed;
          color: $label-btn-primary-pressed;
          border: 1px solid $border-btn-primary-pressed;
        }

        &:disabled {
          background: $btn-primary-disabled;
          color: $label-btn-primary-disabled;
          border: 1px solid $border-btn-primary-disabled;
        }
      }
    }
    .section-two {
      border-left: 0.5px solid $mega-divider;
      border-right: 0.5px solid $mega-divider;
    }
    .section-two,
    .section-three {
      padding-left: 20px;
      padding-right: 20px;

      nav {
        display: flex;
        flex-direction: column;
        padding-bottom: 96px;
        color: $mega-text-color !important;

        a {
          align-items: center;
          color: $mega-text-color !important;
          display: flex;
          font-weight: 400;
          font-size: $text-base;
          padding-bottom: 8px;
        }
        .head {
          font-weight: 500;
          color: $mega-title-color !important;
          padding-bottom: 12px;
        }
      }
    }
    .section-four {
      align-items: flex-end;
      justify-self: right;
      figure {
        width: 288px;
        height: 288px;
        position: relative;

        > div {
          border-radius: $border-radius;
        }
      }
      div {
        width: 288px;
        .title {
          align-items: center;
          color: $mega-title-color;
          display: flex;
          font-size: $text-base;
          font-weight: 500;
          line-height: 24px;
          padding: 24px 0px 8px;
        }
        .subtitle {
          font-weight: 400;
          font-size: $text-base;
          line-height: 24px;
          color: $mega-description-color;
        }
      }
    }
  }
}
